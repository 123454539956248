export default function PageLoading() {
  return (
    <div className="page-loading">
      <div className="page-loading-01"></div>
      <div className="page-loading-02"></div>
      <div className="page-loading-03"></div>
      <div className="page-loading-04"></div>
      <div className="page-loading-05"></div>
    </div>
  )
}
