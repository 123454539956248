export default function UseCaseCard({ useCase }) {
  return (
    <div className="use-case-card">
      <div className="use-case-logo">
        <img src={'/use-cases/' + useCase.logo} alt={useCase.title} />
      </div>
      <div className="use-case-body">
        <div className="use-case-title">{useCase.title}</div>
        <div className="use-case-text">{useCase.content}</div>
      </div>
    </div>
  )
}
