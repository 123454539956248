import TableauEmbed from './TableauEmbed'
import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useState, useEffect } from 'react'
import PageLoading from '../PageLoading'

export default function ReportDetail({ report }) {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 3000)
  }, [])

  return (
    <div className="loading-wrapper">
      {loading && (
        <div className="page-loading-wrapper">
          <PageLoading />
        </div>
      )}
      <div className="report-detail">
        <h1 className="report-detail-title">{report.title}</h1>
        {report.gallery && report.gallery.length > 0 ? (
          report.gallery.length > 1 ? (
            <div className="report-detail-gallery">
              <Swiper
                modules={[Pagination]}
                spaceBetween={30}
                pagination={{ clickable: true }}
              >
                {report.gallery.map((img, index) => (
                  <SwiperSlide
                    key={index}
                    className="report-detail-gallery-item"
                  >
                    <img
                      key={index}
                      src={'report-detail/' + img}
                      alt={report.title}
                      width={1050}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          ) : (
            <div className="report-detail-gallery">
              <img
                src={'report-detail/' + report.gallery[0]}
                alt={report.title}
                width={1050}
              />
            </div>
          )
        ) : (
          <div className="report-detail-code">
            <TableauEmbed
              vizId={report.embed.vizId}
              img={report.embed.img}
              name={report.embed.name}
              tabs={report.embed.tabs}
            />
          </div>
        )}
      </div>
    </div>
  )
}
