import { useEffect } from 'react'

export default function ContactForm() {
  useEffect(() => {
    const scriptId = 'hs-script-loader'
    if (document.getElementById(scriptId)) {
      return
    }

    const script = document.createElement('script')
    script.id = scriptId
    script.src = '//js.hsforms.net/forms/embed/v2.js'
    script.charset = 'utf-8'
    script.type = 'text/javascript'
    script.async = true
    document.body.appendChild(script)

    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '8224200',
          formId: 'fe069a3d-9faf-467e-b082-90c146e7f68c',
          target: '.contact',
        })
      }
    }

    return () => {
      const existingScript = document.getElementById(scriptId)
      if (existingScript) {
        document.body.removeChild(existingScript)
      }
    }
  }, [])

  return <div className="contact"></div>
}
