import { useGlobalState } from '../../hooks/useGlobalState'
import { useState, useEffect } from 'react'

export default function InfoContainer() {
  const { activePage, resetToIntroState, formState, resetToFormState } =
    useGlobalState()
  const [activeLink, setActiveLink] = useState(1)

  useEffect(() => {
    if (formState > activeLink) {
      setTimeout(() => {
        setActiveLink(formState)
      }, 450)
    } else if (formState < activeLink) {
      setActiveLink(formState)
    }
  }, [formState, activeLink])
  return (
    <div
      className={`info-container ${activePage === 'intro' ? 'is-active' : ''} is-state-${formState}`}
    >
      <button
        type="button"
        className={`question-link is-1 ${activeLink === 1 ? 'is-active' : ''} ${formState > 1 ? 'is-finished' : ''}`}
        onClick={() => resetToFormState(1)}
        disabled={formState < 2}
      >
        <span>1</span>
      </button>
      <button
        type="button"
        className={`question-link is-2 ${activeLink === 2 ? 'is-active' : ''} ${formState > 2 ? 'is-finished' : ''}`}
        onClick={() => resetToFormState(2)}
        disabled={formState < 3}
      >
        <span>2</span>
      </button>
      <button
        type="button"
        className={`question-link is-3 ${activeLink === 3 ? 'is-active' : ''} ${formState > 3 ? 'is-finished' : ''}`}
        onClick={() => resetToFormState(3)}
        disabled={formState < 4}
      >
        <span>3</span>
      </button>
      <button
        type="button"
        className={`question-link is-4 ${activeLink === 4 ? 'is-active' : ''} ${formState > 4 ? 'is-finished' : ''}`}
        onClick={() => resetToFormState(4)}
        disabled={formState < 5}
      >
        <span>4</span>
      </button>
      <button
        type="button"
        className={`question-link is-5 ${activeLink === 5 ? 'is-active' : ''} ${formState > 5 ? 'is-finished' : ''}`}
        onClick={() => resetToFormState(5)}
        disabled={formState < 6}
      >
        <span>5</span>
      </button>
      <button
        type="button"
        className={`question-link is-6 ${activeLink === 6 ? 'is-active' : ''}`}
      >
        <span>6</span>
      </button>
      <button
        type="button"
        className={`info-link ${activePage !== 'intro' ? 'is-active' : ''}`}
        onClick={() => resetToIntroState()}
      >
        Intro
      </button>
    </div>
  )
}
