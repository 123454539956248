const answerData = [
  {
    answer: 'aaaaaa',
    reports: [1, 2, 3, 15, 18, 20],
    reviews: [1, 2, 4, 6],
    useCases: [1, 2, 5],
  },
  {
    answer: 'aaaaab',
    reports: [1, 2, 3, 10, 15, 18, 19, 20],
    reviews: [1, 2, 4, 3, 6],
    useCases: [1, 2, 5, 4],
  },
  {
    answer: 'aaaaba',
    reports: [1, 2, 3, 15, 18, 20],
    reviews: [1, 2, 4, 6],
    useCases: [1, 2, 5],
  },
  {
    answer: 'aaaabb',
    reports: [1, 2, 3, 10, 15, 18, 19, 20],
    reviews: [1, 2, 4, 3, 6],
    useCases: [1, 2, 5, 4],
  },
  {
    answer: 'aaabaa',
    reports: [1, 3, 5, 11, 12, 13, 17, 18],
    reviews: [1, 4, 2, 6],
    useCases: [1, 5, 9, 2],
  },
  {
    answer: 'aaabab',
    reports: [1, 3, 5, 10, 11, 12, 13, 17, 18, 19],
    reviews: [1, 4, 3, 2, 6],
    useCases: [1, 5, 9, 4, 2],
  },
  {
    answer: 'aaabba',
    reports: [1, 3, 5, 11, 12, 13, 17, 18],
    reviews: [1, 4, 2, 6],
    useCases: [1, 5, 9, 2],
  },
  {
    answer: 'aaabbb',
    reports: [1, 3, 5, 10, 11, 12, 13, 17, 18, 19],
    reviews: [1, 4, 3, 2, 6],
    useCases: [1, 5, 9, 4, 2],
  },
  {
    answer: 'aabaaa',
    reports: [1, 2, 3, 15, 18, 20],
    reviews: [1, 2, 4, 6],
    useCases: [1, 2, 5],
  },
  {
    answer: 'aabaab',
    reports: [1, 2, 3, 10, 15, 18, 19, 20],
    reviews: [1, 2, 4, 3, 6],
    useCases: [1, 2, 5, 4],
  },
  {
    answer: 'aababa',
    reports: [1, 2, 3, 15, 18, 20],
    reviews: [1, 2, 4, 6],
    useCases: [1, 2, 5],
  },
  {
    answer: 'aababb',
    reports: [1, 2, 3, 10, 15, 18, 19, 20],
    reviews: [1, 2, 4, 3, 6],
    useCases: [1, 2, 5, 4],
  },
  {
    answer: 'aabbaa',
    reports: [1, 3, 4, 5, 9, 11, 12, 13, 17, 18],
    reviews: [1, 4, 8, 2, 6],
    useCases: [1, 5, 9, 8, 2],
  },
  {
    answer: 'aabbab',
    reports: [1, 3, 4, 5, 8, 9, 10, 11, 12, 13, 17, 18, 19],
    reviews: [1, 4, 8, 3, 2, 6],
    useCases: [1, 5, 9, 8, 4, 2],
  },
  {
    answer: 'aabbba',
    reports: [1, 3, 4, 5, 9, 11, 12, 13, 17, 18],
    reviews: [1, 4, 8, 2, 6],
    useCases: [1, 5, 9, 8, 2],
  },
  {
    answer: 'aabbbb',
    reports: [1, 3, 4, 5, 8, 9, 10, 11, 12, 13, 17, 18, 19],
    reviews: [1, 4, 8, 3, 2, 6],
    useCases: [1, 5, 9, 8, 4, 2],
  },
  {
    answer: 'abaaaa',
    reports: [1, 2, 3, 4, 5, 6, 7, 11, 12, 13, 14, 15, 16, 17, 20],
    reviews: [1, 2, 4],
    useCases: [1, 2, 5, 9, 3, 4],
  },
  {
    answer: 'abaaab',
    reports: [1, 2, 3, 4, 5, 6, 7, 8, 10, 11, 12, 13, 14, 15, 16, 17, 19, 20],
    reviews: [1, 2, 4, 3],
    useCases: [1, 2, 5, 9, 3, 4],
  },
  {
    answer: 'abaaba',
    reports: [1, 2, 3, 4, 5, 6, 7, 11, 12, 13, 14, 15, 16, 17, 20],
    reviews: [1, 2, 4],
    useCases: [1, 2, 5, 9, 3, 4],
  },
  {
    answer: 'abaabb',
    reports: [1, 2, 3, 4, 5, 6, 7, 8, 10, 11, 12, 13, 14, 15, 16, 17, 19, 20],
    reviews: [1, 2, 4, 3],
    useCases: [1, 2, 5, 9, 3, 4],
  },
  {
    answer: 'ababaa',
    reports: [1, 3, 4, 5, 6, 7, 11, 13, 17],
    reviews: [1, 4, 2],
    useCases: [1, 5, 9, 3, 4, 2],
  },
  {
    answer: 'ababab',
    reports: [1, 3, 4, 5, 6, 7, 10, 11, 13, 17, 19],
    reviews: [1, 4, 3, 2],
    useCases: [1, 5, 9, 3, 4, 2],
  },
  {
    answer: 'ababba',
    reports: [1, 3, 4, 5, 6, 7, 11, 13, 17],
    reviews: [1, 4, 2],
    useCases: [1, 5, 9, 3, 4, 2],
  },
  {
    answer: 'ababbb',
    reports: [1, 3, 4, 5, 6, 7, 10, 11, 13, 17, 19],
    reviews: [1, 4, 3, 2],
    useCases: [1, 5, 9, 3, 4, 2],
  },
  {
    answer: 'abbaaa',
    reports: [1, 2, 3, 4, 5, 6, 7, 9, 11, 12, 13, 14, 15, 16, 17, 18, 20, 21],
    reviews: [1, 2, 4, 8, 6],
    useCases: [1, 2, 5, 9, 3, 4, 8],
  },
  {
    answer: 'abbaab',
    reports: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    ],
    reviews: [1, 2, 4, 8, 3, 6],
    useCases: [1, 2, 5, 9, 3, 4, 8],
  },
  {
    answer: 'abbaba',
    reports: [1, 2, 3, 4, 5, 6, 7, 9, 11, 12, 13, 14, 15, 16, 17, 18, 20],
    reviews: [1, 2, 4, 8, 6],
    useCases: [1, 2, 5, 9, 3, 4, 8],
  },
  {
    answer: 'abbabb',
    reports: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    ],
    reviews: [1, 2, 4, 8, 3, 6],
    useCases: [1, 2, 5, 9, 3, 4, 8],
  },
  {
    answer: 'abbbaa',
    reports: [1, 3, 4, 5, 6, 7, 9, 11, 13, 17, 18],
    reviews: [1, 4, 8, 2, 6],
    useCases: [1, 5, 9, 3, 4, 8, 2],
  },
  {
    answer: 'abbbab',
    reports: [1, 3, 4, 5, 6, 7, 9, 10, 11, 13, 17, 18, 19],
    reviews: [1, 4, 8, 3, 2, 6],
    useCases: [1, 5, 9, 3, 4, 8, 2],
  },
  {
    answer: 'abbbba',
    reports: [1, 3, 4, 5, 6, 7, 9, 11, 13, 17, 18],
    reviews: [1, 4, 8, 2, 6],
    useCases: [1, 5, 9, 3, 4, 8, 2],
  },
  {
    answer: 'abbbbb',
    reports: [1, 3, 4, 5, 6, 7, 9, 10, 11, 13, 17, 18, 19],
    reviews: [1, 4, 8, 3, 2, 6],
    useCases: [1, 5, 9, 3, 4, 8, 2],
  },
  {
    answer: 'acaaaa',
    reports: [1, 2, 3, 4, 5, 6, 7, 9, 11, 12, 13, 14, 15, 16, 17, 20, 21],
    reviews: [1, 2, 4, 8],
    useCases: [1, 2, 5, 9, 3, 4, 8],
  },
  {
    answer: 'acaaab',
    reports: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 10, 12, 13, 14, 15, 16, 17, 19, 20, 21,
    ],
    reviews: [1, 2, 4, 8, 3],
    useCases: [1, 2, 5, 9, 3, 4, 8],
  },
  {
    answer: 'acaaba',
    reports: [1, 2, 3, 4, 5, 6, 7, 9, 11, 12, 13, 14, 15, 16, 17, 20],
    reviews: [1, 2, 4, 8],
    useCases: [1, 2, 5, 9, 3, 4, 8],
  },
  {
    answer: 'acaabb',
    reports: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 19, 20,
    ],
    reviews: [1, 2, 4, 8, 3],
    useCases: [1, 2, 5, 9, 3, 4, 8],
  },
  {
    answer: 'acabaa',
    reports: [1, 3, 4, 5, 6, 7, 9, 11, 13, 16, 17],
    reviews: [1, 4, 8, 2],
    useCases: [1, 5, 9, 3, 4, 8, 2],
  },
  {
    answer: 'acabab',
    reports: [1, 3, 4, 5, 6, 7, 9, 10, 11, 13, 16, 17, 19],
    reviews: [1, 4, 8, 3, 2],
    useCases: [1, 5, 9, 3, 4, 8, 2],
  },
  {
    answer: 'acabba',
    reports: [1, 3, 4, 5, 6, 7, 9, 11, 13, 16, 17],
    reviews: [1, 4, 8, 2],
    useCases: [1, 5, 9, 3, 4, 8, 2],
  },
  {
    answer: 'acabbb',
    reports: [1, 3, 4, 5, 6, 7, 9, 10, 11, 13, 16, 17, 19],
    reviews: [1, 4, 8, 3, 2],
    useCases: [1, 5, 9, 3, 4, 8, 2],
  },
  {
    answer: 'acbaaa',
    reports: [1, 2, 3, 4, 5, 6, 7, 9, 11, 12, 13, 14, 15, 16, 17, 18, 20, 21],
    reviews: [1, 2, 4, 8, 6],
    useCases: [1, 2, 5, 9, 3, 4, 8],
  },
  {
    answer: 'acbaab',
    reports: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    ],
    reviews: [1, 2, 4, 8, 3, 6],
    useCases: [1, 2, 5, 9, 3, 4, 8],
  },
  {
    answer: 'acbaba',
    reports: [1, 2, 3, 4, 5, 6, 7, 9, 11, 12, 13, 14, 15, 16, 17, 18, 20],
    reviews: [1, 2, 4, 8, 6],
    useCases: [1, 2, 5, 9, 3, 4, 8],
  },
  {
    answer: 'acbabb',
    reports: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    ],
    reviews: [1, 2, 4, 8, 3, 6],
    useCases: [1, 2, 5, 9, 3, 4, 8],
  },
  {
    answer: 'acbbaa',
    reports: [1, 3, 4, 5, 6, 7, 9, 11, 13, 16, 17, 18],
    reviews: [1, 4, 8, 2, 6],
    useCases: [1, 5, 9, 3, 4, 8, 2],
  },
  {
    answer: 'acbbab',
    reports: [1, 3, 4, 5, 6, 7, 9, 10, 11, 13, 16, 17, 18, 19],
    reviews: [1, 4, 8, 3, 2, 6],
    useCases: [1, 5, 9, 3, 4, 8, 2],
  },
  {
    answer: 'acbbba',
    reports: [1, 3, 4, 5, 6, 7, 9, 11, 13, 16, 17, 18],
    reviews: [1, 4, 8, 2, 6],
    useCases: [1, 5, 9, 3, 4, 8, 2],
  },
  {
    answer: 'acbbbb',
    reports: [1, 3, 4, 5, 6, 7, 9, 10, 11, 13, 16, 17, 18, 19],
    reviews: [1, 4, 8, 3, 2, 6],
    useCases: [1, 5, 9, 3, 4, 8, 2],
  },
]

export default answerData
