import React, { useState, useEffect } from 'react'
import { useGlobalState } from '../hooks/useGlobalState'

import IntroPage from './Intro/IntroPage'
import ReportPage from './Reports/ReportPage'
import GalleryPage from './Reports/GalleryPage'
import UseCasesPage from './UseCases/UseCasesPage'
import ReferencesPage from './References/ReferencesPage'
import LogoPage from './Logo/LogoPage'
import ContactForm from './Contact/ContactForm'

export default function MainContent() {
  const { activePage } = useGlobalState()
  const [page, setPage] = useState(activePage)
  const [animationClass, setAnimationClass] = useState('fade-enter-active')

  useEffect(() => {
    if (activePage !== page) {
      setAnimationClass('fade-exit-active')
      setTimeout(() => {
        setPage(activePage)
        setAnimationClass('fade-enter-active')
      }, 300)
    }
  }, [activePage, page])

  const renderPage = () => {
    switch (page) {
      case 'logo':
        return <LogoPage />
      case 'intro':
        return <IntroPage />
      case 'reports':
        return <ReportPage />
      case 'use-cases':
        return <UseCasesPage />
      case 'references':
        return <ReferencesPage />
      case 'gallery':
        return <GalleryPage />
      case 'contact':
        return <ContactForm />
      default:
        return null
    }
  }

  return (
    <main
      className={activePage !== 'logo' ? 'app-main has-sidebar' : 'app-main'}
    >
      <div className={`${animationClass}`}>{renderPage()}</div>
    </main>
  )
}
