import references from '../../data/references'
import ReferenceList from './ReferenceList'

export default function ReferencesPage() {
  return (
    <div className="references-page">
      <ReferenceList references={references} />
    </div>
  )
}
