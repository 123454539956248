import 'modern-normalize/modern-normalize.css'
import './App.css'
import 'swiper/css'
import 'swiper/css/pagination'
import { StateProvider } from './components/StateProvider'
import MainWrapper from './components/MainWrapper'

function App() {
  return (
    <>
      <StateProvider>
        <MainWrapper />
      </StateProvider>
    </>
  )
}

export default App
