import useCases from '../../data/useCases'
import UseCaseList from './UseCaseList'
import { useGlobalState } from '../../hooks/useGlobalState'

export default function UseCasesPage() {
  const { filteredUseCasesIds } = useGlobalState()
  const filteredUseCases = useCases.filter((useCase) =>
    filteredUseCasesIds.includes(useCase.id)
  )
  return (
    <div className="use-case-page">
      <UseCaseList useCases={filteredUseCases} />
    </div>
  )
}
