import { useGlobalState } from '../hooks/useGlobalState'
import SidebarNav from './Sidebar/SidebarNav'
import MainContent from './MainContent'

export default function MainWrapper() {
  const { activePage } = useGlobalState()
  return (
    <div className={`app ${activePage !== 'logo' ? 'has-sidebar' : ''}`}>
      {activePage !== 'logo' && <SidebarNav />}
      <MainContent />
    </div>
  )
}
