export default function ReferenceCard({ reference }) {
  return (
    <div className="reference-card">
      <div className="reference-image">
        <div>
          <img src={'/references/' + reference.image} alt={reference.title} />
        </div>
      </div>
      <div className="reference-body">
        <div className="reference-text">{reference.content}</div>
        <div className="reference-title">{reference.title}</div>
      </div>
    </div>
  )
}
