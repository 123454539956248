import { useGlobalState } from '../../hooks/useGlobalState'
import { useState, useEffect } from 'react'

export default function NavMenu() {
  const {
    activePage,
    handleChangeActivePage,
    menuVisible,
    setMenuVisible,
    answers,
  } = useGlobalState()
  const [emptyAnswers, setEmptyAnswers] = useState(true)

  useEffect(() => {
    const allAnswersFilled = Object.keys(answers)
      .filter((key) => Number(key) >= 1 && Number(key) <= 6)
      .every((key) => answers[key] !== '')
    setEmptyAnswers(allAnswersFilled)
  }, [answers, emptyAnswers])
  return (
    <div
      className={`navbar-wrapper ${activePage !== 'intro' ? 'is-active' : ''}`}
    >
      <button
        type="button"
        className={`navbar-button ${!menuVisible ? 'is-active' : ''} `}
        onClick={() => setMenuVisible(true)}
      >
        Menu
      </button>
      <nav className={`navbar ${menuVisible ? 'is-active' : ''}`}>
        <ul>
          {emptyAnswers && (
            <li>
              <button
                type="button"
                className={
                  activePage === 'reports'
                    ? 'navbar-link is-active'
                    : 'navbar-link'
                }
                onClick={() => handleChangeActivePage('reports')}
              >
                Reports
              </button>
            </li>
          )}
          <li>
            <button
              type="button"
              className={
                activePage === 'use-cases'
                  ? 'navbar-link is-active'
                  : 'navbar-link'
              }
              onClick={() => handleChangeActivePage('use-cases')}
            >
              Use cases
            </button>
          </li>
          <li>
            <button
              type="button"
              className={
                activePage === 'references'
                  ? 'navbar-link is-active'
                  : 'navbar-link'
              }
              onClick={() => handleChangeActivePage('references')}
            >
              References
            </button>
          </li>
          <li>
            <button
              type="button"
              className={
                activePage === 'gallery'
                  ? 'navbar-link is-active'
                  : 'navbar-link'
              }
              onClick={() => handleChangeActivePage('gallery')}
            >
              Gallery
            </button>
          </li>
          <li>
            <button
              type="button"
              className={
                activePage === 'contact'
                  ? 'navbar-link is-active'
                  : 'navbar-link'
              }
              onClick={() => handleChangeActivePage('contact')}
            >
              Contact
            </button>
          </li>
        </ul>
      </nav>
    </div>
  )
}
