import InfoContainer from './InfoContainer'
import NavMenu from './NavMenu'

export default function SidebarNav() {
  return (
    <header className="app-header">
      <InfoContainer />
      <NavMenu />
    </header>
  )
}
