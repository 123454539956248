const useCases = [
  {
    id: 1,
    title: 'SPORTISIMO',
    logo: 'sportisimo.png',
    content: (
      <>
        <h2>Challenge</h2>
        <p>
          Prodejní data klienta z online a offline kanálů byla izolována, což
          bránilo jejich schopnosti získat komplexní pohled na deduplikovaný
          prodejní výkon napříč kanály.
        </p>
        <h2>Solution</h2>
        <p>
          Použili jsme nástroj ETL k propojení dat z online a offline prodejních
          systémů, vytvářejíc tak jeden soubor dat, ke kterému má klient nyní
          přístup pro reporting a analýzy.
        </p>
        <h2>Result</h2>
        <p>
          Sloučením dat z e-shopu a kamenných prodejen má nyní Sportisimo
          sjednocený pohled na své hlavní prodejní kanály. To jim umožňuje
          plánovat marketingové aktivity efektivněji a využívat synergii mezi
          všemi kanály. Díky využití predikce poptávky založené na datové vědě
          společnost dosáhla 10% snížení nákladů na provoz skladu,
          optimalizovala svůj dodavatelský řetězec a zvýšila celkový výkon.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: 'Mall Group',
    logo: 'mall.svg',
    content: (
      <>
        <h2>Challenge</h2>
        <p>
          MALL Group se snažil zvýšit efektivitu práce s daty, demokratizovat
          přístup pro informované rozhodování a podporovat kulturu řízenou daty.
          Pro zajistění plynulého přijetí a snadného získání cenných poznatků z
          dat sehrála klíčovou roli vizualizace dat.
        </p>
        <h2>Solution</h2>
        <p>
          Díky své analytické transformaci se MALL Group stala úspěšnou
          dynamickou společností. Například MALL může monitorovat marže pro
          každý z 300 000 produktů a dynamicky stanovovat ceny. Během dvou let
          se přehoupnula z červených čísel do černých.
        </p>
      </>
    ),
  },
  {
    id: 3,
    title: 'PetCenter',
    logo: 'petcenter.png',
    content: (
      <>
        <h2>Challenge</h2>
        <p>
          Společnost Pet Centrum spolupracovala se společností Revolt na
          transformaci nákupu a řízení zásob na základě datových doporučení.
        </p>
        <h2>Solution</h2>
        <p>
          Jeden z mnoha příkladů - společnost úspěšně předvídala vyprodání zásob
          a za pouhé 3 měsíce zabránila potenciálním ztrátám prodeje ve výši 200
          000 EUR.
        </p>
      </>
    ),
  },
  {
    id: 4,
    title: 'Košík.cz',
    logo: 'kosik.svg',
    content: (
      <>
        <h2>Challenge</h2>
        <p>
          Internetový prodejce potravin, který na svých webových stránkách
          nabízí přibližně 15 tisíc položek a zajišťuje doručení nákupu
          zákazníkům až domů. Pro řízení obchodu bylo zapotřebí kompletní
          analytiky.
        </p>
        <h2>Solution</h2>
        <p>
          Díky tomuto řešení má obchodní oddělení přehled o aktuálním i
          historickém chování tisíců produktů a o stavu dodávek klientům.
          Aktualizace probíhají denně nebo častěji, což lze snadno konfigurovat
          podle potřeby. Sledování aktuálních událostí umožňuje rychle reagovat
          na vzniklou situaci.
        </p>
        <h2>Result</h2>
        <p>
          Revolt BI zpracovává kompletní analytické výstupy pro celou společnost
          od skladu až po marketing. Vytvořil datový sklad a zpracoval
          automatizované reporty pro provozní, obchodní a marketingové oddělení.
          Bylo připraveno přes 700 reportů z 10 datových zdrojů dostupných v
          reálném čase a denní agregaci.
        </p>
      </>
    ),
  },
  {
    id: 5,
    title: 'Zoot',
    logo: 'zoot.png',
    content: (
      <>
        <h2>Challenge</h2>
        <p>
          V rámci optimalizace nákladů je třeba rozhodnout o uzavření některých
          výdejen. Otázka: "Pokud se některá prodejna uzavře, o kolik příjmů
          přijde a kolik se přesune jinam?"
        </p>
        <h2>Solution</h2>
        <p>
          Rozdělení zákazníků obchodu na stálé klienty, příležitostné klienty a
          jednorázové. Kvantifikace a agregace chování jednotlivých skupin v
          reálných a hypotetických scénářích.
        </p>
        <h2>Result</h2>
        <p>
          Vyčíslení, kolik procent zákazníků a tržeb se po uzavření prodejny
          přesune do jiného obchodu a kolik se jich přesune na internet.
          Předpovídání finančního dopadu uzavření jedné nebo více prodejen.
          Úspory v řádu statisíců Kč měsíčně.
        </p>
      </>
    ),
  },
  {
    id: 6,
    title: 'Fokus Optik',
    logo: 'fokusoptik.png',
    content: (
      <>
        <h2>Challenge</h2>
        <p>
          Rozdělení údajů o zákaznících mezi dvě společnosti a systémy
          představuje promarněný marketingový potenciál.
        </p>
        <h2>Solution</h2>
        <p>
          Koordinační práce mezi správci dat umožnila sloučit všechny zdroje dat
          do jediného Data Martu, který obsahoval analýzu RFM. To umožnilo lepší
          rozhodování a plánování marketingových aktivit.
        </p>
        <h2>Result</h2>
        <p>
          Centralizace všech relevantních dat uvolnila potenciál cílených
          marketingových kampaní a pokročilých projektů business intelligence.
        </p>
      </>
    ),
  },
  {
    id: 7,
    title: 'BTL Medical Technologies',
    logo: 'biomedical.png',
    content: (
      <>
        <h2>Challenge</h2>
        <p>
          Klient se potýkal s problémy spojenými s nesprávnými a zpožděnými
          daty, což omezovalo jeho schopnost reagovat na měnící se podmínky a
          činit informovaná rozhodnutí. Absence demokratizace dat bránila
          efektivnímu využívání dat v rámci organizace, přičemž stávající
          interní řešení nesplňovala požadavky na kvalitu a funkcionalitu.
        </p>
        <h2>Result</h2>
        <p>
          Vytvořili jsme pro klienta dokument analytické strategie, který
          usměrňoval jeho transformaci na společnost řízenou daty. To zahrnovalo
          identifikaci rolí, prioritizaci konkrétních použití a doporučení
          technologií. Tato strategie vedla k plánu a odhadu nákladů na
          implementaci, zahrnující interní i externí náklady. Klient obdržel
          jasnou roadmapu pro svou cestu k tomu, aby se stal společností řízenou
          daty.
        </p>
      </>
    ),
  },
  {
    id: 8,
    title: 'STOCK',
    logo: 'stock.png',
    content: (
      <>
        <h2>Challenge</h2>
        <p>
          STOCK Plzeň-Božkov, přední česká společnost v oblasti výroby lihovin,
          byla jednou z prvních, která začala s integrací rozhodování založeného
          na datech do svého obchodního modelu. V roce 2018 nás oslovila s
          požadavkem “pomozte nám sjednotit sell-out data a zefektivnit tak
          reporting v Tableau”. Hlavním usecasem bylo namapovat data třetích
          stran na interní data o prodejích.
        </p>
        <h2>Solution</h2>
        <p>
          STOCK Plzeň-Božkov zpracovává data o prodejích ze stovek prodejen a
          různých prodejních kanálů, od online obchodů po velké řetězce. Tato
          data do STOCKu plynula v různých formátech. Potřebu sjednocení
          datových zdrojů Revolt BI řeší u každého datového projektu, proto jsme
          ji dokázali vyřešit rychle a efektivně.
        </p>
        <h2>Result</h2>
        <p>
          Díky úspěšné implementaci řešení může STOCK může efektivně sledovat
          end-to-end výkonnost svých marketingových kanálů.
        </p>
      </>
    ),
  },
  {
    id: 9,
    title: 'CARVAGO',
    logo: 'carvago.png',
    content: (
      <>
        <h2>Challenge</h2>
        <p>
          Aby ve svých začátcích Carvago dosáhlo svých cílů, potřebovalio každý
          den najít v celé Evropě nabízené ojeté vozy a zařadit je do své
          nabídky. Aby jejich zákazníci mohli kdykoliv najít tu nejvýhodnější
          nabídku hledaného vozu, je třeba neustále doplňovat databázi o nově
          nabízené vozy na všech portálech v Evropě. Jejich prodejci přitom
          často nedostatečně vyplní data o nabízeném autě nebo umístí inzerát
          zároveň na několik portálů. Bylo tak potřeba nejen získat nové
          inzeráty, ale zároveň odstranit duplicity a chyby.
        </p>
        <h2>Solution</h2>
        <p>
          Řešení od Revolt BI pro Carvago zahrnuje několik součástí - Tvorba
          datového skladu pro katalog i inzeráty, získávání dat, analýza dat a
          business analytika. Jako datový sklad a DevOps platformu jsme zvolili
          Keboola s datovým úložištěm na Snowflake, kdy rozhodl hlavně výpočetní
          výkon, integrace všech potřebných služeb, diagnostika všech procesů a
          mnoho dalších výhod řešení Keboola. Pro automatickou analýzu
          fotografií (image recognition) používáme deep learning. Řešení je
          navrženo tak, aby na základě fotografií dokázalo opravit chybovost
          nebo doplnit chybné informace.
        </p>
        <h2>Result</h2>
        <p>
          Díky spolupráci s Revolt.BI získalo Carvago a vždy aktuální katalog s
          více než 3 000 modelů, 250 značek vozů a kompletní záznamy o klíčových
          parametrech.
        </p>
      </>
    ),
  },
]

export default useCases
