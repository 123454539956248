import { useGlobalState } from '../../hooks/useGlobalState'

export default function IntroPage() {
  const {
    formState,
    setFormState,
    handleChangeActivePage,
    setAnswer,
    answers,
    handleFinishQuestions,
    selectAllReferences,
    selectAllUseCases,
  } = useGlobalState()

  const handleButtonClick = (question, answer, formState) => {
    if (formState === question) {
      setAnswer(question, answer)
      setFormState(question + 1)
    } else if (formState > question) {
      setAnswer(question, '')
      setFormState(question)
    }
  }

  const handleFinishForm = (answer) => {
    handleFinishQuestions(answer)
  }

  const goToGallery = () => {
    setFormState(6)
    handleChangeActivePage('gallery')
    selectAllReferences()
    selectAllUseCases()
  }

  const isAnswerActive = (question, answer) => {
    return answers[question] === answer
  }

  return (
    <div className="intro-page">
      <div
        className={`question ${formState === 1 ? 'is-active' : ''} ${formState > 1 ? 'is-answered' : ''}`}
      >
        <h2 className="question-title">V jakém odvětví podnikáte?</h2>
        <div className="question-answers">
          <button
            className={`button ${isAnswerActive(1, 'a') ? 'is-active' : ''}`}
            type="button"
            onClick={() => handleButtonClick(1, 'a', formState)}
          >
            Retail
          </button>
          <button
            className={`button ${isAnswerActive(1, 'b') ? 'is-active' : ''}`}
            type="button"
            onClick={() => goToGallery()}
          >
            E-commerce
          </button>
          <button
            className={`button ${isAnswerActive(1, 'c') ? 'is-active' : ''}`}
            type="button"
            onClick={() => goToGallery()}
          >
            Health Care
          </button>
        </div>
      </div>
      <div
        className={`question ${formState === 2 ? 'is-active' : ''} ${formState > 2 ? 'is-answered' : ''}`}
      >
        <h2 className="question-title">Kolik má vaše firma poboček?</h2>
        <div className="question-answers">
          <button
            className={`button ${isAnswerActive(2, 'a') ? 'is-active' : ''}`}
            type="button"
            onClick={() => handleButtonClick(2, 'a', formState)}
          >
            žádné
          </button>
          <button
            className={`button ${isAnswerActive(2, 'b') ? 'is-active' : ''}`}
            type="button"
            onClick={() => handleButtonClick(2, 'b', formState)}
          >
            do 500
          </button>
          <button
            className={`button ${isAnswerActive(2, 'c') ? 'is-active' : ''}`}
            type="button"
            onClick={() => handleButtonClick(2, 'c', formState)}
          >
            nad 500
          </button>
        </div>
      </div>
      <div
        className={`question ${formState === 3 ? 'is-active' : ''} ${formState > 3 ? 'is-answered' : ''}`}
      >
        <h2 className="question-title">Jaký je Váš roční obrat?</h2>
        <div className="question-answers">
          <button
            className={`button ${isAnswerActive(3, 'a') ? 'is-active' : ''}`}
            type="button"
            onClick={() => handleButtonClick(3, 'a', formState)}
          >
            do 1 miliardy ročně
          </button>
          <button
            className={`button ${isAnswerActive(3, 'b') ? 'is-active' : ''}`}
            type="button"
            onClick={() => handleButtonClick(3, 'b', formState)}
          >
            nad 1 miliardu ročně
          </button>
        </div>
      </div>
      <div
        className={`question ${formState === 4 ? 'is-active' : ''} ${formState > 4 ? 'is-answered' : ''}`}
      >
        <h2 className="question-title">Máte eshop?</h2>
        <div className="question-answers">
          <button
            className={`button ${isAnswerActive(4, 'a') ? 'is-active' : ''}`}
            type="button"
            onClick={() => handleButtonClick(4, 'a', formState)}
          >
            ano
          </button>
          <button
            className={`button ${isAnswerActive(4, 'b') ? 'is-active' : ''}`}
            type="button"
            onClick={() => handleButtonClick(4, 'b', formState)}
          >
            ne
          </button>
        </div>
      </div>
      <div
        className={`question ${formState === 5 ? 'is-active' : ''} ${formState > 5 ? 'is-answered' : ''}`}
      >
        <h2 className="question-title">
          Prodáváte privátní značky nebo koupené?
        </h2>
        <div className="question-answers">
          <button
            className={`button ${isAnswerActive(5, 'a') ? 'is-active' : ''}`}
            type="button"
            onClick={() => handleButtonClick(5, 'a', formState)}
          >
            jen privátní
          </button>
          <button
            className={`button ${isAnswerActive(5, 'b') ? 'is-active' : ''}`}
            type="button"
            onClick={() => handleButtonClick(5, 'b', formState)}
          >
            koupené i privátní
          </button>
        </div>
      </div>
      <div
        className={`question ${formState === 6 ? 'is-active' : ''} ${formState > 6 ? 'is-answered' : ''}`}
      >
        <h2 className="question-title">Kolik máte SKUček?</h2>
        <div className="question-answers">
          <button
            className={`button ${isAnswerActive(6, 'a') ? 'is-active' : ''}`}
            type="button"
            onClick={() => handleFinishForm('a')}
          >
            do 5 000 SKUček
          </button>
          <button
            className={`button ${isAnswerActive(6, 'b') ? 'is-active' : ''}`}
            type="button"
            onClick={() => handleFinishForm('b')}
          >
            nad 5 000 SKUček
          </button>
        </div>
      </div>
    </div>
  )
}
