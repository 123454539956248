import UseCaseCard from './UseCaseCard'
import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

export default function useCaseList({ useCases }) {
  return (
    <div className="use-case-wrapper">
      <div className="use-case-list">
        <Swiper
          modules={[Pagination]}
          spaceBetween={30}
          pagination={{ clickable: true }}
        >
          {useCases.map((useCase, index) => (
            <SwiperSlide key={index} className="use-case-item">
              <UseCaseCard key={index} useCase={useCase} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}
