import { useState, useEffect } from 'react'
import ReportList from './ReportList'
import ReportDetail from './ReportDetail'
import { useGlobalState } from '../../hooks/useGlobalState'
import reportsData from '../../data/reports'

export default function GalleryPage() {
  const { activeReport } = useGlobalState()
  const [displayedComponent, setDisplayedComponent] = useState(null)
  const [animationClass, setAnimationClass] = useState('fade-enter-active')

  useEffect(() => {
    setAnimationClass('fade-exit-active')
    setTimeout(() => {
      setDisplayedComponent(
        activeReport ? (
          <ReportDetail report={activeReport} />
        ) : (
          <ReportList reports={reportsData} />
        )
      )
      setAnimationClass('fade-enter-active')
    }, 300)
  }, [activeReport])

  return (
    <div className={`report-page ${animationClass}`}>{displayedComponent}</div>
  )
}
