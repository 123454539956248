import { useRef, useEffect, useState } from 'react'
import ReportItem from './ReportItem'

export default function ReportList({ reports }) {
  const containerRef = useRef()
  const listRef = useRef()
  const [isOverflowing, setIsOverflowing] = useState(false)

  useEffect(() => {
    const checkHeight = () => {
      const containerHeight = containerRef.current.offsetHeight
      const listHeight = listRef.current.offsetHeight

      if (listHeight > containerHeight) {
        setIsOverflowing(true)
      } else {
        setIsOverflowing(false)
      }
    }

    checkHeight()
    window.addEventListener('resize', checkHeight)

    return () => window.removeEventListener('resize', checkHeight)
  }, [reports])

  return (
    <div className={`report-wrapper ${isOverflowing ? 'is-overflowing' : ''}`}>
      <div
        className={`report-container ${isOverflowing ? 'is-overflowing' : ''}`}
        ref={containerRef}
      >
        <div className="report-list" ref={listRef}>
          {reports.map((report) => (
            <div className="report-item" key={report.id}>
              <ReportItem report={report} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
