import React, { useState, useEffect, createContext } from 'react'
import answerData from '../data/answers'
import references from '../data/references'
import useCases from '../data/useCases'

export const StateContext = createContext()

export function StateProvider({ children }) {
  const [menuVisible, setMenuVisible] = useState(false)
  const [activePage, setActivePage] = useState('logo')
  const [activeReport, setActiveReport] = useState('')
  const [formState, setFormState] = useState(1)
  const [answers, setAnswers] = useState({
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
  })
  const [filteredReportsIds, setFilteredReportsIds] = useState([])
  const [filteredUseCasesIds, setFilteredUseCasesIds] = useState([])
  const [filteredReferencesIds, setFilteredReferencesIds] = useState([])

  useEffect(() => {
    if (Object.values(answers).every((answer) => answer !== '')) {
      const { reportIds, useCaseIds, referenceIds } = getFilteredIds(
        answers,
        answerData
      )
      handleSetFiltered(reportIds, useCaseIds, referenceIds)
    }
  }, [answers])

  const handleChangeActivePage = (page) => {
    setActivePage(page)
    setActiveReport('')
  }

  const setAnswer = (question, answer) => {
    setAnswers({ ...answers, [question]: answer })
    if (question !== 6) {
      setFormState(question + 1)
    }
  }

  const resetAnswers = () => {
    setAnswers({
      1: '',
      2: '',
      3: '',
      4: '',
      5: '',
      6: '',
    })
  }

  const resetToFormState = (state) => {
    setFormState(state)
    const newAnswers = {}
    for (let i = 1; i <= 6; i++) {
      newAnswers[i] = i < state ? answers[i] : ''
    }
    setAnswers(newAnswers)
  }

  const resetToIntroState = () => {
    setMenuVisible(false)
    setActivePage('intro')
    setActiveReport('')
    setFormState(1)
    resetAnswers()
    setFilteredReportsIds([])
    setFilteredUseCasesIds([])
    setFilteredReferencesIds([])
  }

  function getFilteredIds(answers, answerData) {
    const answersPattern = Object.values(answers).join('')
    const matchingData = answerData.find(
      (data) => data.answer === answersPattern
    )

    if (matchingData) {
      return {
        reportIds: matchingData.reports,
        useCaseIds: matchingData.useCases,
        referenceIds: matchingData.reviews,
      }
    }

    return {
      reportIds: [],
      useCaseIds: [],
      referenceIds: [],
    }
  }

  const handleSetFiltered = (reportIds, useCaseIds, referenceIds) => {
    setFilteredReportsIds(reportIds)
    setFilteredUseCasesIds(useCaseIds)
    setFilteredReferencesIds(referenceIds)
  }

  const handleFinishQuestions = (answer) => {
    setAnswer(6, answer)
    handleChangeActivePage('reports')
  }

  const selectAllUseCases = () => {
    const useCasesIds = useCases.map((useCase) => useCase.id)
    setFilteredUseCasesIds(useCasesIds)
  }

  const selectAllReferences = () => {
    const referenceIds = references.map((reference) => reference.id)
    setFilteredReferencesIds(referenceIds)
  }

  return (
    <StateContext.Provider
      value={{
        menuVisible,
        setMenuVisible,
        activePage,
        activeReport,
        setActiveReport,
        handleChangeActivePage,
        formState,
        setFormState,
        answers,
        setAnswer,
        setAnswers,
        resetAnswers,
        resetToIntroState,
        resetToFormState,
        filteredReportsIds,
        filteredUseCasesIds,
        filteredReferencesIds,
        handleSetFiltered,
        handleFinishQuestions,
        selectAllReferences,
        selectAllUseCases,
      }}
    >
      {children}
    </StateContext.Provider>
  )
}
