import ReferenceCard from './ReferenceCard'
import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useGlobalState } from '../../hooks/useGlobalState'

export default function ReferenceList({ references }) {
  const { filteredReferencesIds } = useGlobalState()
  const filteredReferences = references.filter((reference) =>
    filteredReferencesIds.includes(reference.id)
  )

  return (
    <div className="reference-wrapper">
      <div className="reference-list">
        <Swiper
          modules={[Pagination]}
          spaceBetween={30}
          pagination={{ clickable: true }}
        >
          {filteredReferences.map((reference, index) => (
            <SwiperSlide key={index} className="reference-item">
              <ReferenceCard key={index} reference={reference} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}
