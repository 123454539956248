const references = [
  {
    id: 1,
    image: 'sportisimo.jpeg',
    title: (
      <>
        <u>Aleksei Siparau</u>, Logistics and BI Director, Sportisimo
      </>
    ),
    content: (
      <>
        "Do budoucna nám predikce poptávky pomůže také lépe predikovat tok zboží
        na prodejnách, a zajistit tak, abychom měli stále relevantní nabídku a
        hlavně spokojené zákazníky, kteří u nás vždy najdou to, co potřebují.
        Věřím, že transformace na data-driven společnost výrazně posiluje naši
        konkurenceschopnost."
      </>
    ),
  },
  {
    id: 2,
    image: 'mall.png',
    title: (
      <>
        <u>Andy Werner</u>, Head of BI at MALL Group
      </>
    ),
    content: (
      <>
        "Skutečnost, že nyní více nasloucháme datům, nám umožňuje lépe a
        rychleji reagovat na požadavky trhu. Výsledkem je, že transformace na
        společnost řízenou daty pro nás znamená více flexibilní a strategické
        provozní postupy a rozhodování."
      </>
    ),
  },
  {
    id: 3,
    image: 'kosik.jpg',
    title: (
      <>
        <u>Ondřej Lorenc</u>, Head of BI, Košík.cz
      </>
    ),
    content: (
      <>
        "Revolt je náš dlouholetý spolehlivý Tableau partner. Pomohli nám
        například s náročnou migrací Tableau serveru do Azure, spojenou s
        upgradem a změnou autentizace. Jejich přístup je vždy profesionální a
        férový."
      </>
    ),
  },
  {
    id: 4,
    image: 'zoot.jpeg',
    title: (
      <>
        <u>Lukáš Haubelt</u>, Head of performance, Zoot
      </>
    ),
    content: (
      <>
        "Díky segmentační analýze mohl vyhrát ZOOT Effie award za zvýšení
        konverze z retenčního emailingu o 30%."
      </>
    ),
  },
  {
    id: 5,
    image: 'bi.png',
    title: (
      <>
        <u>Radan Papoušek</u>, Head of BI, Euromedia Group
      </>
    ),
    content: (
      <>
        "Revolt BI je jedním z důležitých prvků v transformaci Euromedie na
        inovativní datově řízenou společnost. Díky dobře nastavené koncepci máme
        data o celé firmě, na která se můžeme spolehnout a analyzovat je.
        Spolupráce s Revoltem je výborná. Projekty řídí precizně, dokáží
        flexibilně reagovat i na ad hoc požadavky, mají erudované odborníky s
        potřebnými praktickými zkušenostmi, a to vše při zachování vstřícného a
        přátelského přístupu."
      </>
    ),
  },
  {
    id: 6,
    image: 'skoda.jpg',
    title: (
      <>
        <u>Miloš Zejval</u>, Projektový koordinátor, Škoda Auto
      </>
    ),
    content: (
      <>
        "Způsob, jakým Revolt BI přistupuje k zadáním, je v dnešním
        technologickém prostředí jedinečný. Jejich cílem není jen najít řešení
        problému, ale problém vyřešit a doručit výsledek. Mezi tím vědět, jak, a
        skutečně to udělat přitom bývá často velký rozdíl a spousta práce a
        zkušeností. Přesně to se projevilo i v případě optimalizace skladů ve
        Škoda Auto, kdy vymysleli elegantní řešení a bez zbytečného otálení či
        komplikací ho také uvedli do praxe."
      </>
    ),
  },
  {
    id: 7,
    image: 'medical.jpg',
    title: (
      <>
        <u>Vojtěch Telupil</u>, Head of IT/IS, BTL Medical Technologies
      </>
    ),
    content: (
      <>
        "Dlouhodobě jsme se potýkali s problémem dosahování pokroku ve dvou
        klíčových parametrech: mít data správně a včas. Analytická strategie,
        kterou pro nás Revolt implementoval, poskytla rámec provedení, prakticky
        roadmapu pro implementaci BI, což nám umožnilo efektivně prezentovat
        nové BI našim kolegům. Musím také ocenit pozornost věnovanou přípravě
        individuálních use cases a datového modelu. To, co nejvíce oceňuji při
        spolupráci s Revoltem, je jejich odbornost a zkušenosti, které do naší
        společnosti přinesli, a také důraz, který kladou na rozvíjení vzdělávání
        v rámci organizace."
      </>
    ),
  },
  {
    id: 8,
    image: 'stock.jpg',
    title: (
      <>
        <u>Tomáš Vrabec</u>, Sales Analyses manager, STOCK Plzeň-Božkov
      </>
    ),
    content: (
      <>
        "Díky Revolt BI jsme schopni dělat rychlá a správná byznys rozhodnutí.
        Na Revolt BI je spoleh, vážím si jejich profesionality, jasné komunikace
        a ochoty proaktivně řešit všechny problémy, co přijdou – a to v
        rekordním čase. Revolt BI je naším datovým partnerem od roku 2018."
      </>
    ),
  },
]

export default references
