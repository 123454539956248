import { useGlobalState } from '../../hooks/useGlobalState'
export default function LogoPage() {
  const { handleChangeActivePage } = useGlobalState()
  return (
    <div className="logo-page">
      <div className="logo-wrapper">
        <button
          type="buton"
          className="logo-screensaver"
          onClick={() => handleChangeActivePage('intro')}
        >
          <div className="logo-revolt logo-1"></div>
          <div className="logo-revolt logo-2"></div>
        </button>
      </div>
    </div>
  )
}
