const reportsData = [
  {
    id: 1,
    title: 'TOPLINE',
    embed: {
      vizId: 'viz1711359923463',
      img: 'https://public.tableau.com/static/images/TO/TOPLINEREPORTAppVersion/FINANCE/1.png',
      name: 'TOPLINEREPORTAppVersion/FINANCE',
      tabs: 'no',
    },
  },
  {
    id: 2,
    title: 'Online Marketing Topline',
    embed: {
      vizId: 'viz1711359954107',
      img: 'https://public.tableau.com/static/images/On/OnlineMarketingToplineAppVersion/SPENDDASHBOARDRD/1.png',
      name: 'OnlineMarketingToplineAppVersion/SPENDDASHBOARDRD',
      tabs: 'no',
    },
  },
  {
    id: 3,
    title: 'UNDERSTOCK / OVERSTOCK',
    embed: {
      vizId: 'viz1711359992334',
      img: 'https://public.tableau.com/static/images/Un/Understock_OverstockInventoryAnalysisAppVersion/INVENTORYANALYSIS/1.png',
      name: 'Understock_OverstockInventoryAnalysisAppVersion/INVENTORYANALYSIS',
      tabs: 'no',
    },
  },
  {
    id: 4,
    title: 'Retail stock availability (nákupní)',
    embed: {
      vizId: 'viz1711360012671',
      img: 'https://public.tableau.com/static/images/Op/OptimalstocklevelinstoresAppVersion/Version1ENG/1.png',
      name: 'OptimalstocklevelinstoresAppVersion/Version1ENG',
      tabs: 'no',
    },
  },
  {
    id: 5,
    title: 'Profit & Loss Statement',
    embed: {
      vizId: 'viz1711360043448',
      img: 'https://public.tableau.com/static/images/Pr/ProfitLossStatementAppVersion/PL/1.png',
      name: 'ProfitLossStatementAppVersion/PL',
      tabs: 'no',
    },
  },
  {
    id: 6,
    title: 'RETAIL STORE PERFORMANCE REPORT',
    embed: {
      vizId: 'viz1711360059878',
      img: 'https://public.tableau.com/static/images/RE/RETAILSTOREPERFORMANCEREPORTAppVersion/PRODEJNA/1.png',
      name: 'RETAILSTOREPERFORMANCEREPORTAppVersion/PRODEJNA',
      tabs: 'no',
    },
  },
  {
    id: 7,
    title: 'RETAIL STORE TRAFFIC & CONVERSION',
    embed: {
      vizId: 'viz1711360076315',
      img: 'https://public.tableau.com/static/images/RE/RETAILSTORETRAFFICANDCONVERSIONAppVersion/ALLSTORES/1.png',
      name: 'RETAILSTORETRAFFICANDCONVERSIONAppVersion/ALLSTORES',
      tabs: 'no',
    },
  },
  {
    id: 8,
    title: 'PRODUCTS CATEGORY ROLE IN PET STORE',
    embed: {
      vizId: 'viz1711360092765',
      img: 'https://public.tableau.com/static/images/PR/PRODUCTSCATEGORYROLEINPETSTOREAppVersion/Introduction/1.png',
      name: 'PRODUCTSCATEGORYROLEINPETSTOREAppVersion/Introduction',
      tabs: 'no',
    },
  },
  {
    id: 9,
    title: 'MARKET PERFORMANCE',
    embed: {
      vizId: 'viz1711360108463',
      img: 'https://public.tableau.com/static/images/Ma/MarketPerformanceAppVersion/NielsenSalesElectro/1.png',
      name: 'MarketPerformanceAppVersion/NielsenSalesElectro',
      tabs: 'no',
    },
  },
  {
    id: 10,
    title: 'Picking Dashboard Analytický',
    embed: {
      vizId: 'viz1711360124900',
      img: 'https://public.tableau.com/static/images/Pi/PickingDashboardAnalytickAppVersion/Dash/1.png',
      name: 'PickingDashboardAnalytickAppVersion/Dash',
      tabs: 'no',
    },
  },
  {
    id: 11,
    title: 'Budget Controlling',
    embed: {
      vizId: 'viz1711116710056',
      img: 'https://public.tableau.com/static/images/Re/Revolt_BI_CORPORATE_FINANCE_-_Budget_Controlling/Home/1.png',
      name: 'Revolt_BI_CORPORATE_FINANCE_-_Budget_Controlling/Home',
      tabs: 'no',
    },
  },
  {
    id: 12,
    title: 'B2B RFM analýza',
    embed: {
      vizId: 'viz1711116856389',
      img: 'https://public.tableau.com/static/images/Re/Revolt_BI_SALES_-_Customer_Segmentation_-_RFM_Analysis/Home/1.png',
      name: 'Revolt_BI_SALES_-_Customer_Segmentation_-_RFM_Analysis/Home',
      tabs: 'no',
    },
  },
  {
    id: 13,
    title: 'B2B Retail Sales',
    embed: {
      vizId: 'viz1710881437168',
      img: 'https://public.tableau.com/static/images/Bi/BioGroceriesSalesAppVersion/Home/1.png',
      name: 'BioGroceriesSalesAppVersion/Home',
      tabs: 'no',
    },
  },
  {
    id: 14,
    title: 'Email Marketing Campaigns',
    embed: {
      vizId: 'viz1711116898897',
      img: 'https://public.tableau.com/static/images/Em/EmailMarketingCampaignsAppVersion/Home/1.png',
      name: 'EmailMarketingCampaignsAppVersion/Home',
      tabs: 'no',
    },
  },
  {
    id: 15,
    title: 'Customer Insights with Data Cloud',
    embed: {
      vizId: 'viz1711116944592',
      img: 'https://public.tableau.com/static/images/Da/DataCloudAcceleratorAppVersion/CustomerCohort/1.png',
      name: 'DataCloudAcceleratorAppVersion/CustomerCohort',
      tabs: 'yes',
    },
  },
  {
    id: 16,
    title: 'Customer Cohort Analysis',
    embed: {
      vizId: 'viz1710881648764',
      img: 'https://public.tableau.com/static/images/Cu/CustomerCohortReportAppVersion/CustomerCohortReport/1.png',
      name: 'CustomerCohortReportAppVersion/CustomerCohortReport',
      tabs: 'no',
    },
  },
  {
    id: 17,
    title: 'Sales, Customer, and Product Performance',
    embed: {
      vizId: 'viz1710881737066',
      img: 'https://public.tableau.com/static/images/Sa/SalesToplineAppVersion/Topline/1.png',
      name: 'SalesToplineAppVersion/Topline',
      tabs: 'no',
    },
  },
  {
    id: 18,
    title: 'Škoda Geopolitical Risks',
    embed: {
      vizId: 'viz1710881818087',
      img: 'https://public.tableau.com/static/images/Sk/SkodaGeoRisksAppVersion/GeopoliticalRiskLevel/1.png',
      name: 'SkodaGeoRisksAppVersion/GeopoliticalRiskLevel',
      tabs: 'no',
    },
  },
  {
    id: 19,
    title: 'Employee Cost Analysis',
    embed: {
      vizId: '',
      img: '',
      name: '',
    },
    gallery: ['19-01.jpg', '19-02.jpg'],
  },
  {
    id: 20,
    title: 'Customer Experience',
    embed: {
      vizId: 'viz1711108404397',
      img: 'https://public.tableau.com/static/images/Pr/PrivateBeautyClinicPatientExperienceOverviewAppVersion/OverviewDashboard/1.png',
      name: 'PrivateBeautyClinicPatientExperienceOverviewAppVersion/OverviewDashboard',
      tabs: 'yes',
    },
  },
  {
    id: 21,
    title: 'Kanibalizace',
    embed: {
      vizId: '',
      img: '',
      name: '',
    },
    gallery: [
      '21-brand-aimilarity-static.jpg',
      '21-brand-similarity.jpg',
      '21-compare-correlation-analysis.jpg',
      '21-google-trends.jpg',
      '21-seasonality.jpg',
    ],
  },
  {
    id: 22,
    title: 'Customer Experience Framework',
    embed: {
      vizId: '',
      img: '',
      name: '',
    },
    gallery: ['22-large.jpg'],
  },
  {
    id: 23,
    title: 'Customer Experience Dashboard',
    embed: {
      vizId: '',
      img: '',
      name: '',
    },
    gallery: ['23-large.jpg'],
  },
]

export default reportsData
